<template>
  <div class="container h-100 py-5">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col">
        <p><span class="h2">Your Cart </span><span class="h4">
            ({{ Object.keys(items).length > 1
                ? Object.keys(items).length + ' Items'
                : Object.keys(items).length + ' Item' }})
        </span></p>

        <div class="card mb-4" v-for="(item, key) in items" :key="item">
          <div class="card-body p-4">
          <input type="checkbox" v-model="checked" :value="key" style="width: 25px; height: 25px;">
            <div class="d-flex justify-content-evenly gap-4 align-items-center">
              <div class="">
                <img :src="item.image" class="img-fluid" alt="Product Image" width="150">
              </div>
              <div class=" d-flex justify-content-center">
                <div>
                  <p class="small text-muted mb-4 pb-2">Name</p>
                  <p class="lead fw-normal mb-0">{{ item.title }}</p>
                </div>
              </div>
              <div class=" d-flex justify-content-center w-25">
                <div>
                  <p class="small text-muted mb-4 pb-2">Quantity</p>
                  <div class="d-flex gap-2 align-items-center">
                    <button type="button" class="bg-transparent border-0" @click="decrease(item)" :disabled="item.quantity <= 1"><i class="bi bi-dash"></i></button>
                    <!-- <input type="number" class="lead fw-normal w-25 border-0 border-bottom border-dark" min="1" disabled> -->
                    <span class="lead fw-normal">{{ item.quantity }}</span>
                    <button type="button" class="bg-transparent border-0" @click="increase(item)"><i class="bi bi-plus"></i></button>
                  </div>
                </div>
              </div>
              <div class=" d-flex justify-content-center">
                <div>
                  <p class="small text-muted mb-4 pb-2">Price</p>
                  <p class="lead fw-normal mb-0">{{ item.price }}</p>
                </div>
              </div>
              <div class=" d-flex justify-content-center">
                <div>
                  <p class="small text-muted mb-4 pb-2">Total</p>
                  <p class="lead fw-normal mb-0">{{ item.total.toFixed(2) }}</p>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="card mb-5">
          <div class="card-body p-4">

            <div class="float-end">
              <p class="mb-0 me-5 d-flex align-items-center">
                <span class="small text-muted me-2">Order total:</span> <span class="lead fw-normal">{{ grand_total.toFixed(2) }}</span>
              </p>
            </div>

          </div>
        </div>

        <div class="d-flex justify-content-end">
          <router-link to="/products" class="btn btn-light btn-lg me-2 text-decoration-none">Continue shopping</router-link>
          <button type="button" class="btn btn-primary btn-lg" @click="request" :disabled="!checked.length">Request Quotation</button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// import { getCookie } from "../assets/js/create-cookie"
export default {
    name: 'Cart',
    data() {
        return {
            items: localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : {},
            grand_total: 0,
            checked: []
        }
    },
    mounted() {
        for(var item in this.items) {
            this.grand_total += this.items[item].total
        }
    },
    methods: {
        increase(item) {
            item.quantity += 1
            item.total = item.price * item.quantity
            this.grand_total += item.price
        },
        decrease(item) {
            if(item.quantity <= 1) return
            item.quantity -= 1
            item.total = item.price * item.quantity
            this.grand_total -= item.price
        },
        update_quant(item) {
            var last_quantity = item.quantity
            item.total = item.price * item.quantity
            if(last_quantity > item.quantity) {
                this.grand_total += item.price * (item.quantity - 1)
            }
        },
        request() {
          if(this.checked.length) {
            const arr = Object.values(this.items)
            var new_obj = {}
            for(const i of this.checked) {
              if(arr.find((_, index) => i == index)) {
                new_obj[Object.values(new_obj).length] = arr[i]
                delete this.items[i]
                this.grand_total = 0
                for(var item in this.items) {
                  this.grand_total += this.items[item].total
                }
              }
            }
            if(Object.values(new_obj).length) {
              localStorage.setItem('cart', JSON.stringify(this.items))
              localStorage.setItem('to_request', JSON.stringify(new_obj))
              this.$router.push('/request-quotation')
            }
            else {
              localStorage.removeItem('cart')
            }
          }
          return
        }
    }
}
</script>